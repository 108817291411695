import BasePlugin from '../BasePlugin'

export default class SendForApprovalInvestmentRequest extends BasePlugin {
  async execute () {
    let investmentRequestId = this.context.getModel().id
    let me = this
    const h = this.context.$createElement

    let response =
      await this.context.$http.get(`${this.context.$config.api}/registryservice/plugins/execute/CheckAdjustmentRequestDuplicateQuery?investmentRequestId=${investmentRequestId}`)

    if (response.data.is_adjustment_requests_exist) {
      this.context.$msgbox({
        title: 'Согласование инвестзаявки',
        message: 'Вы действительно хотите отправить на согласование данную инвестзаявку?',
        showCancelButton: true,
        confirmButtonText: 'Да',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SendForApprovalInvestmentRequestCommand`,
              { 'investmentRequestId': investmentRequestId },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              })
            this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddIntegrationNumbersForInvestmentExpensesBudgetAndInvestmentBudgetCommand`,
              { 'investmentRequestId': investmentRequestId },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              })
          }
          this.context.$msgbox.close()
          this.context.getCard().$emit('cancelChanges')
        }
      })
    } else {
      this.context.$msgbox({
        title: 'Отправка инвестзаявки на согласование',
        message: h('dashboard', { props: { id: 150, parentContext: this.context, model: JSON.parse(JSON.stringify(this.context.getModel())) }, key: this.context.generateGuid() }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    }
  }
}
